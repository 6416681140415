import React from "react"
import Typography from "@material-ui/core/Typography"

const ContactInfo = () =>
   <div id="contact_info" style={{padding:"16px", minWidth: "250px"}}>
      <Typography variant="body1" color="textPrimary" >
         EMAIL:
         <br/>
         grymstrength@gmail.com
         <br/>
         <br/>
         TEL:
         <br/>
         780-996-6588
         <br/>
         <br/>
         OPENING HOURS:
         <br/>
         Mon - Sun: 7am - 10pm
      </Typography>
   </div>


export default ContactInfo;