import React from "react"

import TextField from "@material-ui/core/TextField"
import PrimaryButton from "../styles/PrimaryButton"


const ContactForm = () => {
   return (
     <form
       name="contact"
       method="post"
       action="/thanks/"
       data-netlify="true"
       data-netlify-honeypot="bot-field"
       // style={{width:'100%'}}
       style={{ padding: "16px", minWidth: "250px" }}
     >
       {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
       <input type="hidden" name="form-name" value="contact" />
       <p hidden>
         {" "}
         <label>
           {" "}
           Don’t fill this out: <input name="bot-field" />{" "}
         </label>{" "}
       </p>
       <TextField id="contact-name" name="name" label="Your name:" fullWidth />
       <br />
       <TextField
         id="contact-email"
         name="email"
         label="Your email:"
         fullWidth
       />
       <br />
       <TextField
         id="contact-message"
         name="message"
         label="Message"
         multiline
         rows="8"
         fullWidth
       />
       <br />
       <br />
       <PrimaryButton type="submit" >
         Send
       </PrimaryButton>
     </form>
   )
}

export default ContactForm;