import React from "react"

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import Layout from "../components/layout"
import PageLayout from "../components/layout-page"
import SEO from "../components/seo"
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'



const Contact = () => {
   return (
      <Layout>
         <SEO title="Contact" />
         <PageLayout title="Contact">
            <div style={{ display: "flex", justifyContent:"center"}}>
               <Card style={{margin:'25px'}}>
                  <CardContent style={{display:'flex', flexWrap:"wrap", alignItems:"center"}}>
                     <ContactInfo />
                     <ContactForm />
                  </CardContent>
               </Card>
            </div>
         </PageLayout>
      </Layout>
  )
}

export default Contact;